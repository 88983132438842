import React, {useEffect, useState} from "react";
import Buttons from "./Buttons";
import Question from "./Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import {motion} from "framer-motion";

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==", true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        getIdPlaying()
    }, [])

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box sx={{height: '100%'}}>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: '100%'}}
                   spacing={0}>
                <motion.div
                    style={{paddingInline:'1rem', height:'15vh', background: 'rgba(19,86,113,1)'}}
                    transition={{duration: 0.5, ease: "easeInOut"}}
                >
                    <Stack sx={{height:'100%'}} justifyContent={'end'}>
                        <Typography variant={'h2'} textAlign={'left'} fontWeight={'bold'} lineHeight={1}>
                            Persone
                        </Typography>
                    </Stack>
                </motion.div>
                <motion.div
                    style={{height: '85vh',display:'flex', flexDirection: 'column', justifyContent: 'space-between'}}
                >
                    <Box sx={{ paddingInline:'1rem', }}>
                        <Typography variant={'h2'} textAlign={'left'} fontWeight={'bold'} lineHeight={1} color={'rgba(19,86,113,1)'}>
                            e imprese,
                            <br/>
                            parola alle
                            <br/>
                            competenze
                        </Typography>
                        {
                            event.data().autenticazione &&
                            <DisplayName displayName={displayName} setDisplayName={setDisplayName} editing={editing} setEditing={setEditing}/>
                        }
                        {!pinnedLoading && !pinnedError && pinned ?
                            event.data().autenticazione
                                ?
                                (displayName !== '' && displayName) &&
                                <PinnedQuestions questions={pinned}/>
                                :
                                <PinnedQuestions questions={pinned}/>
                            :
                            <></>
                        }
                    </Box>
                    {(!editing && displayName!=='') && <Typography textAlign={'center'} variant={'h4'} fontWeight={'bold'}>
                        Preparati ad interagire!
                    </Typography>}
                    <Box sx={{width:'100%'}}>
                        <img style={{width:'100%', marginBottom:'-0.5rem'}} src={require('../assets/images/evento/partners.png')}/>
                    </Box>
                </motion.div>
            </Stack>
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
