import React from "react";
import {AppBar, Box, Container, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import {Textfit} from 'react-textfit';

export default function QuestionBar({tipo, domanda, colore}) {
    return (
        <Box sx={{height:'25%', width:'100%', background:'rgba(19,86,113,1)'}}>
            <Container sx={{height:'100%'}}>
                <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                    <Textfit mode={'multi'} min={10} max={30}
                             style={{
                                 fontWeight: 'bold',
                                 fontFamily: 'Times New Roman',
                                 width: '100%',
                                 height: '100%',
                                 textAlign: 'center',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center'
                             }}>
                        {domanda}
                    </Textfit>
                </Box>
            </Container>
        </Box>
    )
}
